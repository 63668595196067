
/* ##### About Us Area ##### */
.double-bg{
    /* background: url('../img/svg/section-bg.svg') no-repeat center left; */
    background-size: contain
}
.double-bg-1{
    position: relative;
    /* background: url('../img/bg-img/section-bg.png') no-repeat top right; */
    background-size: cover
}
.about-us-area {
    position: relative;
    z-index: 1;
}
.about-bg{
    position: relative;
    
}
@media (min-width: 1200px){
    .about-bg{
        /* background: url(../img/bg-img/about-bg.png) no-repeat right top; */
        background-size: contain;
        position: absolute;
        width: 71%;
        height: 100%;
        content: '';
        right: -18%;
        top: 0;
        z-index: -1;
    }
}

@-webkit-keyframes fadeInAndOut {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes fadeInAndOut {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes fadeInAndOut {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeInAndOut {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
.who-we-contant h4 {
    font-size: 40px;
    font-family: 'Fugaz One', cursive;
    text-shadow: 0.1em 0.1em 0 hsl(200deg 50% 30%);
}

.our-mission-area {
    position: relative;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    height: 400px !important;
}

.our-mission-content {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(72, 52, 212, 0.95);
    background: -webkit-linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: -webkit-linear-gradient(left, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
}

.single-mission {
    position: relative;
    z-index: 1;
    text-align: center;
}

.single-mission i {
    font-size: 50px;
    margin-bottom: 30px;
    display: block;
    color: #fff;
}

.single-mission h6 {
    margin-bottom: 15px;
}

.single-mission p {
    margin-top: 0;
    margin-bottom: 0
}

.welcome-meter {
    position: relative;
    z-index: 1;
}
.comp-img{
    border-radius: 15px;
    max-width: 80%;
}
.supportImg {
    position: absolute;
    right: 50px;
    top: 0%;
    height: 126px;
    z-index: 1;
    animation: fadeInAndOut 6s ease-in-out 0s infinite alternate;
}
.trade1{
    position: absolute;
    top: 140px;
    right: 37px;
}
.welcome-meter.arficial{
    position: relative;
    z-index: 1;
    height: 427px;
    /* background: url(../img/svg/ai-illustration.svg) no-repeat; */
    background-size: 137%;
    background-position: center center;
}
@media (max-width: 992px){
    .welcome-meter.arficial{
        background-size: 100%;
    }
}
.growing-company p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
}

.growing-company p .counter {
    color: #fff;
}

.box-shadow:hover {
    -webkit-box-shadow: 0px 25px 36px 0px rgba(154, 161, 171, 0.24);
    box-shadow: 0px 25px 36px 0px rgba(154, 161, 171, 0.24);
    top: -3px;
}
.article {
    background: #fff;
    padding: 25px 15px 15px;
    border: 1px solid #00dcd8;
    margin-bottom: 30px;
    text-align: left;
    border-radius: 5px;
    transition: all .4s ease-in-out;
}
.article__icon {
    font-size: 40px;
    position: relative;
    color: #00dcd8;
}
.article__icon .flag-img{
    position: absolute;
    bottom: 19.8%;
    width: 30px;
    right: 12.7%;
}
.article__title {
    font-size: 18px;
    margin-top: 8px;
    color: #333
}
.article p{color: #888}
.article:hover,
.article.hover{
    background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%); 
}
.article:hover .article__title,
.article:hover p,
.article:hover .article__icon,
.article.hover .article__title,
.article.hover p,
.article.hover .article__icon {
    color: #fff 
}