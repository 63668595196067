
/* ##### Partners Area ##### */
.parttns{
    padding: 50px 30px;
    background-color: #1E2738;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}
.partners{
    padding: 0 0 70px
}
.partner-box{
    border: 1px solid #25cbd3;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;
    vertical-align: middle;
    background-color: #0d003b;
    margin-bottom: 30px;
    transition: all .3s ease-in-out;
}
.partner-box:hover{
    background: #25cbd3;
    cursor: pointer;
}
@media (max-width:992px){
    .parttns{
        padding: 50px 30px 30px;
    }
    .parttns img{
        margin-bottom: 20px;
    }
}
.b-text {
    color: #272e3a !important;
}
.w-text{
    color: #fff !important
}
.g-text{
    color: #d8d0d0 !important
}
.bold{
    font-weight: 600 !important
}
.p-text{
    color: blueviolet !important
}
.normal{
    font-weight: 500
}