
/* ##### 13.0 Team Area CSS ##### */

.single-team-member {
    position: relative;
    z-index: 1;
    margin-bottom: 100px;
    background: #1E2738;
    padding: 30px;
    border-radius: 100px 100px 0 0px;
}

.team-member-thumb {
    width: 190px;
    height: 190px;
    border: 7px solid #47DCA8;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    margin:0 auto;
    margin-bottom: 40px;
}

.team-info h5 {
    color: #7057f9;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
}

.team-info p {
    color: #a592b4;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 0;
    text-align: center;
}
.team-social-icon{
    text-align: center;
    position: absolute;
    top: 170px;
    left: 50%;
    transform: translateX(-50%);
}
.team-social-icon a {
    font-size: 22px;
    margin-top: 10px;
    width: 80px;
    height: 80px;
    background: url(../../assets/img/svg/diamond-shape.svg) no-repeat center center;
    background-size: contain;
    color: #fff;
    line-height: 70px;
    display: inline-block;
}