
/*
* ----------------------------------------------------------------------------------------
*  START Roadmap 2 STYLE
* ----------------------------------------------------------------------------------------
*/
.section_5-content {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 200px
}

.section_5-slider-trumb {
    display: inline-block;
    width: 100%;
    left: 0;
    height: 4px;
    background-color: #5892f5;
    position: relative;
    border-radius: 3px
}

#section_5-slider-circle {
    position: relative
}

#section_5-slider-circle .data_cir {
    top: 0;
    position: absolute;
    margin-left: -8px;
    margin-top: -16px;
    width: 16px;
    height: 16px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #5892f5;
    border: 4px solid #000;
    -webkit-box-shadow: 0 0 6px 2px #5892f5;
    -moz-box-shadow: 0 0 6px 2px #5892f5;
    box-shadow: 0 0 6px 2px #5892f5
}

#section_5-slider-circle .data_cir-content {
    position: relative;
    width: 200px;
    height: auto;
    display: inline-block;
    margin-left: -60px;
    text-align: center
}

#section_5-slider-circle .data_cir-content p {
    font-size: 13px;
    color: #5892f5;
    line-height: 2
}

#section_5-slider-circle .data_cir-content>div {
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center
}

#section_5-slider-circle .data_cir-content div {
    font-size: 13px;
    color: #777;
    letter-spacing: 0;
    width: 100%;
    line-height: 20px
}
@media (max-width: 1200px){
    #section_5-slider-circle .data_cir-content div{
        width: 94%;
        font-size: 11px
    }
}
#section_5-slider-circle .data_cir:nth-child(2n+1) .data_cir-content {
    bottom: 44px;
    -webkit-transform: translateY(-100%) translateX(-18%);
    -ms-transform: translateY(-100%) translateX(-18%);
    -moz-transform: translateY(-100%) translateX(-18%);
    -o-transform: translateY(-100%) translateX(-18%);
    transform: translateY(-100%) translateX(-18%)
}

#section_5-slider-circle .data_cir:nth-child(2n) .data_cir-content {
    top: 55px;
    -webkit-transform: translateX(-18%);
    -ms-transform: translateX(-18%);
    -moz-transform: translateX(-18%);
    -o-transform: translateX(-18%);
    transform: translateX(-18%)
}

#section_5-slider-circle .data_cir:nth-child(2n) .data_cir-content .roadmap_img {
    -ms-order: -1;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    -webkit-order: -1;
    -moz-box-ordinal-group: 0;
    order: -1
}

#section_5-slider-circle .data_cir:nth-child(2n) .roadmap_content_info {
    margin-top: 20px
}

#section_5-slider-circle .data_cir:nth-child(2n+1) .roadmap_content_info {
    margin-bottom: 20px
}

#section_5-slider-circle .data_cir .data_cir-content ul {
    text-align: left;
    position: relative
}

#section_5-slider-circle .data_cir .data_cir-content ul::before {
    content: '';
    position: absolute;
    border-left: 1px solid #5892f5;
    left: -14px;
    opacity: .2;
    width: 45%;
    height: -webkit-calc(100% + 37px);
    height: -moz-calc(100% + 37px);
    height: calc(100% + 37px)
}

#section_5-slider-circle .data_cir:nth-child(2n) .data_cir-content ul::before {
    border-top: 1px solid #5892f5;
    margin-top: -46px
}

#section_5-slider-circle .data_cir:nth-child(2n+1) .data_cir-content ul::before {
    border-bottom: 1px solid #5892f5;
    margin-top: 10px
}

#section_5-slider-circle .data_cir .data_cir-content ul li {
    padding-left: 5px;
    list-style: disc;
    color: #5892f5
}

#section_5-slider-circle .data_cir .data_cir-content ul li span {
    color: #777
}
.roadmap .section-heading > p{
	padding-left: 15px;
	padding-right: 15px
}
#section_5-slider-circle .data_cir .data_cir-content .roadmap_img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #5892f5;
    -webkit-box-shadow: 0 0 6px 1px rgba(0, 228, 255, .5);
    -moz-box-shadow: 0 0 6px 1px rgba(0, 228, 255, .5);
    box-shadow: 0 0 6px 1px rgba(0, 228, 255, .5)
}

#section_5-slider-circle .data_cir::before {
    content: '';
    opacity: .6;
    position: absolute;
    display: block;
    width: 1px;
    height: 40px;
    background-color: #5892f5;
    left: -webkit-calc(50% - 1px);
    left: -moz-calc(50% - 1px);
    left: calc(50% - 1px)
}

#section_5-slider-circle .data_cir:nth-child(2n+1)::before {
    top: -44px
}

#section_5-slider-circle .data_cir:nth-child(2n)::before {
    top: 17px
}

#section_5-slider-circle .data_cir::after {
    content: attr(data-date);
    position: absolute;
    display: block;
    color: #222;
    color: #777;
    width: 50px;
    color: #fff;
    font-size: 12px;
    left: 0;
    text-align: center;
    -webkit-transform: translateX(-35%);
    -ms-transform: translateX(-35%);
    -moz-transform: translateX(-35%);
    -o-transform: translateX(-35%);
    transform: translateX(-35%)
}

.form-block-rcl>.login-message {
    color: #fff;
}

#section_5-slider-circle .data_cir:nth-child(2n+1)::after {
    top: 30px
}

#section_5-slider-circle .data_cir:nth-child(2n)::after {
    top: -45px
}

#section_5-slider-circle .data_cir.next {
    background-color: #000;
    border: 4px solid #000;
    -webkit-box-shadow: 0 0 6px 2px #5892f5;
    -moz-box-shadow: 0 0 6px 2px #5892f5;
    box-shadow: 0 0 6px 2px #5892f5
}
@media (max-width: 992px){
    .section_5-slider-trumb {
        width: 4px;
        height: -webkit-calc(100% - 4px);
        height: -moz-calc(100% - 4px);
        height: calc(100% - 4px);
        top: 4px;
        position: absolute;
        left: -webkit-calc(45vw + 8px);
        left: -moz-calc(45vw + 8px);
        left: calc(45vw + 8px);
    }
    .section_5-content {
        position: relative;
    }
    .section_5-content {
        padding-top: 0;
        padding-bottom: 0;
    }
    #section_5-slider-trumb {
        width: 4px;
        height: -webkit-calc(100% - 4px);
        height: -moz-calc(100% - 4px);
        height: calc(100% - 4px);
        top: 4px;
        position: absolute;
        left: -webkit-calc(45vw + 8px);
        left: -moz-calc(45vw + 8px);
        left: calc(45vw + 8px);
    }
    #section_5-slider-circle .data_cir {
        margin-top: 0px;
        margin-bottom: 150px;
        position: relative;
        left: 0!important;
        margin-left: -webkit-calc(45vw + 2px);
        margin-left: -moz-calc(45vw + 2px);
        margin-left: calc(45vw + 2px);
    }
    #section_5-slider-circle .data_cir:last-child{
        margin-bottom: 0!important
    }
    #section_5-slider-circle .data_cir::before {
        top: 3px!important;
        height: 1px;
        width: 40px;
        left: auto;
        right: 14px;
    }
    #section_5-slider-circle .data_cir-content {
        margin-left: 60px;
        -webkit-transform: translateY(0)!important;
        -ms-transform: translateY(0)!important;
        -moz-transform: translateY(0)!important;
        -o-transform: translateY(0)!important;
        transform: translateY(0)!important;
        top: 0!important;
        bottom: 0!important;
        width: 250px;
    }
    #section_5-slider-circle .data_cir-content div{
        width: 100%;
        font-size: 14px;
    }
    #section_5-slider-circle .data_cir:nth-child(2n) .data_cir-content ul::before,
    #section_5-slider-circle .data_cir:nth-child(2n+1) .data_cir-content ul::before{
        display: none;
    }
    #section_5-slider-circle .data_cir:first-child .data_cir-content>div {
    -webkit-transform: none!important;
    -ms-transform: none!important;
    -moz-transform: none!important;
    -o-transform: none!important;
    transform: none!important;
    text-align: left;
}
#section_5-slider-circle .data_cir-content>div {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    height: auto;
}
.roadmap_img {
    display: none!important;
}
#section_5-slider-circle .data_cir::after {
    left: auto;
    right: 55px;
    color: #222;
    padding: 5px 7px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    top: 4px!important;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 1px solid #5892f5;
}
#section_5-slider-circle .data_cir::after {
    width: 70px;
}




}



/*
* ----------------------------------------------------------------------------------------
*  START Roadmap STYLE
* ----------------------------------------------------------------------------------------
*/
.timeline-split{
    position: relative;
    width: 100%
}
.timeline-split .timeline {
   position: relative;
   padding: 50px;
   overflow: hidden
}
.timeline-split .timeline h3{
    font-size: 22px;
    color: #25cbd3
}
.timeline-split .timeline span{
    color: #666;
    display: block;
    margin-bottom: 10px
}
/*top circle */
.timeline-split .timeline::before {
   content: "";
   width: 20px;
   height: 20px;
   border-radius: 50%;
   display: block;
   position: absolute;
   top: 0;
   left: 50%;
   background: #25cbd3;
   left: calc(50% - 10px);
}
/*vertival line */
.timeline-split .timeline::after {
   content: "";
   width: 2px;
   height: 4000px;
   display: block;
   position: absolute;
   top: 10px;
   left: 50%;
   left: calc(50% - 1px);
   background: #25cbd3;
}

/*timeline block */
.timeline-split .timeline .block {
   width: 50%;
    padding: 30px;
    background: #0d003b;
    border: 1px solid #25cbd3;
    width: calc(50% - 74px);
    text-align: left;
    position: relative;
}
.timeline .block .date{
    padding: 5px 10px;
    display: inline-block;
    background: #25cbd3;
    margin: 10px 0;
    color: #fff;
    font-size: 13px;
    border-radius: 15px;
}
.timeline .block .between{
    padding: 5px 10px;
    display: inline-block;
    color: #fff;
}
/*block marker */
.timeline-split .timeline .block::before {
   content: "";
   width: 10px;
   height: 10px;
   border-radius: 50%;
   position: absolute;
   background: #25cbd3;
   top: 30px;
}
.timeline-split .timeline .block.block-left::after,
.timeline-split .timeline .block.block-right::after {
   content: "";
    width: 79px;
    height: 2px;
    position: absolute;
    background: #25cbd3;
    top: 34px;
    z-index: 0;
    right: -78px;
}
.timeline-split .timeline .block.block-right::after{
    left: -80px
}
/*left block */
.timeline-split .timeline .block.block-left {
   text-align: right;
}
.timeline-split .timeline .block.block-left::before {
   right: -80px;
}
.timeline .block p{
    margin-bottom: 0
}
/*right block */
.timeline-split .timeline .block.block-right {
   text-align: left;
   margin-left: 50%;
   margin-left: calc(50% + 74px);
}
.timeline-split .timeline .block.block-right::before {
   left: -80px;
}
/*decrease the timeline heading text */
@media (max-width: 992px) {
   .timeline-split .timeline {
      padding: 50px 20px;
   }

   .timeline-split .timeline h3 {
      font-size: 19px;
   }
}
/*change timeline layout to fit tiny screen size */
@media (max-width: 992px) {
    .timeline-split .timeline .block.block-left::after {
        left: -80px;
    }
   .timeline-split .timeline::after {
      left: 9px;
   }
   .timeline-split .timeline::before {
      left: 0;
   }
   .timeline-split .timeline .circle {
      left: 2px;
   }
   .timeline-split .timeline .block {
      width: 100% !important;
      text-align: left;
      padding-left: 20px;
   }
   .timeline-split .timeline .block::before {
      left: -15px;
   }
   .timeline-split .timeline .block.block-left {
      text-align: left;
   }
   .timeline-split .timeline .block.block-right {
      margin-left: 0;
   }
   .timeline-split .timeline .block.block-right::before {
      left: -15px;
   }
   .mt-30{
    margin-top: 30px
   }
}



/* Roadmap style 2*/

.main-timeline {
    position: relative
}

.main-timeline:before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: #c6c6c6;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.main-timeline .timeline {
    margin-bottom: 40px;
    position: relative
}

.main-timeline .timeline:after {
    content: "";
    display: block;
    clear: both
}

.main-timeline .icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.main-timeline .icon:before,
.main-timeline .icon:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    left: 0;
    transition: all 0.33s ease-out 0s
}

.main-timeline .icon:before {
    background: #fff;
    border: 2px solid #232323;
    left: -3px
}

.main-timeline .icon:after {
    border: 2px solid #c6c6c6;
    left: 3px
}

.main-timeline .timeline:hover .icon:before {
    left: 3px
}

.main-timeline .timeline:hover .icon:after {
    left: -3px
}

.main-timeline .date-content {
    width: 50%;
    float: left;
    margin-top: 30px;
    position: relative;
}

.main-timeline .date-content:before {
    content: "";
    width: 36.5%;
    height: 2px;
    background: #09BE8B;
    margin: auto 0;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
}

.main-timeline .date-outer {
    width: 225px;
    height: 125px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    z-index: 1;
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
    content: "";
    width: 225px;
    height: 125px;
    margin: 0 auto;
    border-radius: 15px;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    transition: all 0.33s ease-out 0s;
}

.main-timeline .date-outer:before {
    background: #09BE8B;
    border: 2px solid #232323;
    left: -6px;
}

.main-timeline .date-outer:after {
    border: 2px solid #fff;
    left: 6px;
}

.main-timeline .timeline:hover .date-outer:before {
    left: 6px
}

.main-timeline .timeline:hover .date-outer:after {
    left: -6px
}

.main-timeline .date {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 27%;
    left: 0
}

.main-timeline .month {
    font-size: 18px;
    font-weight: 700
}

.main-timeline .year {
    display: block;
    font-size: 30px;
    font-weight: 700;
    color: #232323;
    line-height: 36px
}

.main-timeline .timeline-content {
    width: 47%;
    position: relative;
    padding: 30px;
    float: left;
    border-radius: 15px;
    margin-left: 20px;
    background: #1f4629;
}
.main-timeline .timeline-content .arrowo{
    position: absolute;
    background-color: #1f4629;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    z-index: 0;
    top: 25px;
    left: -8px;
}
.main-timeline .title {
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 15px 0
}

.main-timeline .description {
    margin-bottom: 0;
    font-size: 14px
}

.main-timeline .timeline:nth-child(2n) .date-content {
    float: right
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
    left: 10px
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 30px;
    text-align: right;
    margin-left: 0;
    margin-right: 0;
}

.main-timeline .timeline:nth-child(2n) .date-content{
    margin-top: 45px;
}

@media(min-width:992px){
    .main-timeline .timeline:nth-child(2n) .date-content{
        margin-top: 30px;
    }
}

@media(max-width:767px){
    .main-timeline .timeline:nth-child(2n) .timeline-content{
        margin-left: 1.2rem;
    }
}

.main-timeline .timeline:nth-child(2n) .timeline-content .arrowo{
    position: absolute;
    background-color: #1E2738;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    z-index: 0;
    top: 25px;
    left: inherit;
    right: -8px;
}
.main-timeline .timeline:last-child {
    margin-bottom: 0
}
@media only screen and (max-width: 991px) {
    .main-timeline .date-content {
        margin-top: 35px
    }
    .main-timeline .date-content:before {
        width: 22.5%
    }
    .main-timeline .timeline-content {
        padding: 10px 0 10px 30px
    }
    .main-timeline .title {
        font-size: 17px
    }
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding: 10px 30px 10px 0
    }
}

@media only screen and (max-width: 767px) {
    .main-timeline:before {
        margin: 0;
        left: 7px
    }
    .main-timeline .timeline {
        margin-bottom: 30px
    }
    .main-timeline .timeline:last-child {
        margin-bottom: 0
    }
    .main-timeline .icon {
        margin: auto 0
    }
    .main-timeline .date-content {
        width: 95%;
        float: right;
        margin-top: 0;
        margin-bottom: 2rem;
    }
    .main-timeline .date-content:before {
        display: none
    }
    .main-timeline .date-outer {
        width: 110px;
        height: 110px
    }
    .main-timeline .date-outer:before,
    .main-timeline .date-outer:after {
        width: 110px;
        height: 110px
    }
    .main-timeline .date {
        top: 30%
    }
    .main-timeline .year {
        font-size: 24px
    }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        width: 95%;
        text-align: center;
        padding: 10px 0
    }
    .main-timeline .title {
        margin-bottom: 10px
    }
}
